import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { PageLayout, Content } from "../components/PageLayout.tsx";
import LoginForm from "../components/LoginForm.tsx";
import { OrderForm } from "../components/OrderForm.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout("Contact us");
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
  <OrderForm mdxType="OrderForm" />
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      